import React from "react";
import Header from "./components/Header";
import UpcomingEvents from "./Registeration/UpcomingEvents";
import HelpButton from "./components/HelpButton";
import Gallery from "./Registeration/Gallery";
import Sponsors from "./Registeration/SponsorEvent";
import Offers from "./Registeration/EventOffers";
import GetInTouch from "./Registeration/GetInTouch";
import SpeakersList from "./Registeration/SpeakersEvent";
import Footer from "./components/Footer";
const SurveyForm = () => {
//header

  return (
    <>
    <Header />
    <HelpButton />
    <UpcomingEvents />
    <Offers />
    <SpeakersList />
    <Sponsors />
    <Gallery />
    <GetInTouch />
    <Footer />
    </>
  );
};

export default SurveyForm;
