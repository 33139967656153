import React, { useState } from "react";
import { AppBar, Toolbar, Box, Button, Container, IconButton, Drawer } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { motion } from "framer-motion";
import { styled } from "@mui/system";
import { Link } from "react-router-dom"; // ✅ Import Link from React Router

const Logo = styled("img")({
  height: "100px", // Reduced logo size for mobile
  filter: "drop-shadow(2px 2px 6px rgba(0,0,0,0.3))",
  transition: "all 0.3s ease-in-out",
  '@media (max-width: 600px)': { // Adjust logo size for small screens
    height: "80px", 
  }
});

const HeaderButton = styled(Button)({
  color: "#333",
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
  margin: "0 15px",
  letterSpacing: "0.8px",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    color: "#D32F2F",
    transform: "scale(1.1)",
  },
});

const navLinks = [
  { name: "Events", path: "/event" },
  { name: "Register", path: "/" },
  { name: "Speakers", path: "/EventSchedule" },
  { name: "Enquire", path: "/Enquiry" },
];

export default function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <AppBar
      position="sticky"
      sx={{
        background: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(10px)",
        padding: "10px 0",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {/* Logo */}
          <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Link to="/">
              <Logo src="https://edugate-eg.com/wp-content/uploads/2025/02/edutech_logo-1.png" alt="Company Logo" />
            </Link>
          </motion.div>

          {/* Desktop Navigation Links */}
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
            {navLinks.map((link) => (
              <motion.div key={link.name} whileHover={{ scale: 1.1 }}>
                <HeaderButton component={Link} to={link.path}>{link.name}</HeaderButton>
              </motion.div>
            ))}
          </Box>

          {/* Mobile Menu Button */}
          <IconButton sx={{ display: { xs: "block", md: "none" }, color: "#333" }} onClick={() => setMobileOpen(true)}>
            <Menu fontSize="large" />
          </IconButton>

          {/* Mobile Drawer */}
          <Drawer
            anchor="right"
            open={mobileOpen}
            onClose={() => setMobileOpen(false)}
            sx={{ "& .MuiDrawer-paper": { width: "250px", backgroundColor: "#fff", height: "100vh", padding: "25px" } }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "10px" }}>
              {navLinks.map((link) => (
                <Button
                  key={link.name}
                  component={Link}
                  to={link.path}
                  onClick={() => setMobileOpen(false)}
                  sx={{
                    textTransform: "capitalize",
                    color: "#333",
                    fontSize: "1.2rem",
                    padding: "12px 0",
                    fontWeight: "500",
                  }}
                >
                  {link.name}
                </Button>
              ))}
            </Box>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
