import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import EdugateReg from './Registeration/edugate_form';
import EdugateRegEvent from './Registeration/edugate_form_event';
import FeedbackUni from './components/Survey/FeedbackUni';
import Stage from './components/Stage/Stage';
import RegPages from './components/Hotel/RegPages';
import RegPages2 from './components/Hotel/RegPages2';
import Drawer from './Drawer/Drawer';
import Enquiry from './components/Enquiry/Enquiry';
import ProductsPage from './components/Carts/ProductsPage';
import NotFound from './NotFound';
import GotTalent from './components/Got-Talent/GotTalent';
import EventSchedulePage from './components/Stage/EventSchedulePage';
import UnderConstruction from './UnderConstruction'; // Import the Under Construction page
import ErrorBoundary from './ErrorBoundary'; // Import the ErrorBoundary
import Registration_mining from './miningtech/Registration_mining';
import ComingSoon from './components/ComingSoon';
import Survey from './components/Survey/Survey';
import Test from "./eduventure/GlobalSearch"
import Tes from "./Tes"
// import TimeTracker from "./Time-tracker"
// import Test from "./eduventure/Remainder"

function App() {
  useEffect(() => {
    setTimeout(() => {
      const landscapeView = document.querySelector('.landscape-view');
      if (landscapeView) {
        landscapeView.style.transform = 'none';
        landscapeView.style.width = 'auto';
        landscapeView.style.height = 'auto';
        landscapeView.style.overflow = 'visible';
        landscapeView.style.position = 'static';
        landscapeView.style.backgroundColor = 'transparent';
        landscapeView.style.zIndex = 'auto';
        landscapeView.style.transition = 'none';
      }
    }, 1000); 
  }, []);
//      

  return (
    <Router>
<ErrorBoundary>
        <Routes>
          <Route path="/" element={<EdugateReg />} />
          <Route path="/edugateevent" element={<EdugateRegEvent />} />
          <Route path="/FeedbackUni" element={<FeedbackUni />} />
          <Route path="/stage" element={<Stage />} />
          <Route path="/hotelabroad" element={<RegPages />} />
          <Route path="/hotelegypt" element={<RegPages2 />} />
          <Route path="/dashboard" element={<Drawer />} />
          <Route path="/Enquiry" element={<Enquiry />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/GotTalent" element={<GotTalent />} />
          <Route path="/EventSchedule" element={<EventSchedulePage />} />
          <Route path="/under-construction" element={<UnderConstruction />} /> {/* Define the Under Construction route */}
          <Route path="/mining" element={<Registration_mining />} /> {/* Define the Under Construction route */}
          <Route path="/Survey" element={<Survey />} /> {/* Define the Under Construction route */}
          <Route path="/event" element={<Tes />} /> 
        </Routes>
        </ErrorBoundary>
    </Router>
  );
}

export default App;