import React, { useState, useEffect } from 'react';
import './Footer.css';
import axios from 'axios';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function Footer() {
  const [contact, setContact] = useState({});

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/contact', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        });
        setContact(response.data[0]);
      } catch (error) {
        console.error('Error fetching contact data:', error);
      }
    };

    fetchContactData();
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-links">
            <h4>Quick Links</h4>
            <ul>
            <li><a href="/event">Events</a></li>
              <li><a href="/">Register</a></li>
              <li><a href="/EventSchedule">Speakers</a></li>
              <li><a href="/Enquiry">Enquire</a></li>

            </ul>
          </div>

          <div className="footer-contact">
            <h4>Contact Us</h4>
            <p>
              <FaEnvelope className="icon" />{' '}
              <a href={`mailto:info@edugate-eg.com`}>info@edugate-eg.com</a>
            </p>
            <p>
              <FaPhone className="icon" />{' '}
              <a href={`tel:+201000429759`}>+20 1000 42 97 59</a>
            </p>
            <p>
              <LocationOnIcon className="icon" />{' '}
              <a href="https://www.google.com/maps/place/30%C2%B002'22.5%22N+31%C2%B013'59.3%22E/@30.0395916,31.2305561,17z/data=!4m4!3m3!8m2!3d30.039587!4d31.233131?entry=ttu" target="_blank" rel="noopener noreferrer">8 Rostom Pacha street (Mohamed Fahmy Elsayed) Garden City, Cairo</a>
            </p>
          </div>

          <div className="footer-logo">
            <a href="/">
              <img src="https://edugate-eg.com/wp-content/uploads/2023/04/10-YEARS-EDUGATE-copy-1.png" alt="Your Logo" className="responsive-logo" />
            </a>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-social">
            <a href="https://www.facebook.com/edugate.eg" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebook /></a>
            <a href="https://www.instagram.com/edugate.eg/" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram /></a>
            <a href="https://www.linkedin.com/company/edugate-eg/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><FaLinkedin /></a>
          </div>
          <div className="footer-text">
            <p>&copy; {new Date().getFullYear()} EDUGATE. All Rights Reserved.</p>
            <p>Powered by EDUGATE</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
