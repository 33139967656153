import React, { useState, useEffect } from 'react';
import {
  Container, TextField,IconButton, CssBaseline,Button,Tooltip ,Grid, Box,Typography, MenuItem, Select, InputLabel, FormControl, Card, CardContent
  , Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Slide} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { de, enGB, zhCN } from 'date-fns/locale';
import LinearProgress from '@mui/material/LinearProgress';
import { createFilterOptions } from '@mui/material/Autocomplete';
import phoneCodes from '../phoneCodes';
import Autocomplete from '@mui/material/Autocomplete';
import ReportProblem from '../ReportProblem';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import "../../RegistrationForm.css"
import HelpButton from '../HelpButton';
// Define a styled Typography component
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000",
    },
    secondary: {
      main: "#FFD700",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BackgroundContainer = styled('div')({
  minHeight: '100vh',
  width: '100vw', // Ensure full width
  //height: '100vh', // Ensure full height
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '20px',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient from dark red to black
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,

  // Optional: Adding abstract soft shapes or lines
  '::before': {
    content: '""',
    position: 'absolute',
    top: '-50px',
    left: '-50px',
    width: '300px',
    height: '300px',
    background: 'rgba(255, 255, 255, 0.1)', // Subtle white blur
    borderRadius: '50%',
    zIndex: -1,
  },
  '::after': {
    content: '""',
    position: 'absolute',
    bottom: '-100px',
    right: '-100px',
    width: '200px',
    height: '200px',
    background: 'rgba(255, 255, 255, 0.1)', // Subtle white blur on the opposite side
    borderRadius: '50%',
    zIndex: -1,
  },
});

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  maxWidth: '800px',
  margin: 'auto',
  background: 'rgba(255, 255, 255, 0.9)', // Slightly transparent white background
  padding: '40px',
  borderRadius: '20px', // Smooth rounded corners
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)', // Soft and professional shadow
  position:"relative"
});

// Adjusted Button Style
const StyledButton = styled(Button)({
  marginTop: '10px',
  padding: '12px 20px',
  backgroundColor: '#C8102E', // Dark red color
  color: '#fff',
  '&:hover': {
    backgroundColor: '#a00d1e', // Slightly darker shade for hover effect
  },
  transition: 'background-color 0.3s',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  borderRadius: '30px',
});

const BookingForm = () => {
  const [name, setName] = useState('');
  const [university, setUniversity] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [presentation, setPresentation] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [date, setDate] = useState(null);
  const [slot, setSlot] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allSlots, setAllSlots] = useState([]);
  const [description, setDescription] = useState('');
  const [selectedPhoneCode, setSelectedPhoneCode] = useState(null); // State for phone code
  const [event, setEvent] = useState('');
  const [availableEvents, setAvailableEvents] = useState([]);
  const [feedback, setFeedback] = useState({
    message: '',
    type: '',
  });
  const [message, setMessage] = useState("");

  const [progress, setprogress] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const allEvents = response.data;
      const filteredEvents = allEvents.filter(event => event.deleted === "0" && event.has_stage==="1");

      setAvailableEvents(filteredEvents);
    } catch (error) {
    }
};
const handlePhoneCodeChange = (event, newValue) => {
  setSelectedPhoneCode(newValue);
};

  useEffect(() => {
    fetchAvailableEvents();
  }, []);

  const fetchSlots = async (eventId) => {
    try {
      const response = await axios.get(`https://edutech-backend-471f7cb69116.herokuapp.com/api/slots?event=${eventId}`);
      const slots = response.data;

      const allDates = slots.map(slot => slot.date);
      const uniqueDates = [...new Set(allDates)];
      setDate(new Date(allDates[allDates.length-1]));

      setAllSlots(slots);
      setAvailableSlots([]);
      //setDate(null);
      setSlot('');
    } catch (error) {
      setAllSlots([]);
      setAvailableSlots([]);
    }
  };

  useEffect(() => {
    if (event) {
      fetchSlots(event);
    }
  }, [event]);

  useEffect(() => {
    if (date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      const slotsForDate = allSlots.filter(slot => slot.date === formattedDate);
      const availableSlots = slotsForDate.length > 0 ? slotsForDate[0].slots : [];
      setAvailableSlots(availableSlots);
    } else {
      setAvailableSlots([]);
    }
  }, [date, allSlots]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setSlot('');
  };

  const handlePresentationUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ['application/pdf'];
      if (!validFileTypes.includes(file.type)) {
        handleOpenDialog();
        setMessage('Please upload a valid PDF file');
        setPresentation(null);
        return;
      }
      const maxSizeBytes = 5 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        handleOpenDialog();
        setMessage('File size exceeds the limit of 5MB. Please choose a smaller file.');
        setPresentation(null);
        return;
      }

      // Check if file is corrupted
      const reader = new FileReader();
      reader.onload = () => {
        const extension = file.name.split('.').pop();
        const newName = `edugate_speaker_${Date.now()}.${extension}`;
        const renamedFile = new File([file], newName, { type: file.type });
        setPresentation(renamedFile);
      };
      reader.onerror = () => {
        handleOpenDialog();
        setMessage('The uploaded file is corrupted');
        setPresentation(null);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        handleOpenDialog();
        setMessage("Please upload a valid image file (JPEG, PNG)")
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        handleOpenDialog();
        setMessage('File size exceeds the limit of 2MB. Please choose a smaller file.');
        setPhoto(null);
        return;
      }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_speaker_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          handleOpenDialog();
          setMessage('The uploaded file is not a valid image or is corrupted');
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label,
  });


  const handleSlotChange = (event) => {
    setSlot(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!name || !university || !phone || !email || !date || !slot || !event) {
      setFeedback({
        message: "Please fill in all the fields",
        type: 'error',
      });
      setIsSubmitting(false);
      return;
    }
    setprogress(true);

    try {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      const formData = new FormData();
      formData.append('name', name);
      formData.append('university', university);
      formData.append('phone', "+"+selectedPhoneCode.phone+phone);        

      formData.append('email', email);
      formData.append('date', formattedDate);
      formData.append('slot', slot);
      formData.append('presentation', presentation);
      formData.append('photo', photo);
      formData.append('description', description);
      formData.append('event_id', parseInt(event,10));
      formData.append('status', "0");


      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/submit-form', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
        setFeedback({
          message: "Your response was submitted successfully. Thank you for your time.",
          type: 'success',
        });
        setIsSubmitting(true);

      }else if (response.status === 205) {
        setFeedback({
          message: "This slot is no longer available, Please choose a new slot",
          type: 'error',
        });
        setIsSubmitting(false);

      } 
      else {
        setFeedback({
          message: "Error submitting the form",
          type: 'error',
        });
      }
    } catch (error) {
      setFeedback({
        message: "Network error. Please try again later.",
        type: 'error',
      });
    } 
    setprogress(false);

  };

  const isDateDisabled = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    return !allSlots.some(slot => slot.date === formattedDate);
  };
  const handleclose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <div>

    {availableEvents.length === 0 && (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        width: '400px'
      }}>
        <Alert 
          icon={<CheckIcon fontSize="inherit" />} 
          severity="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Registration for the Stage is now Closed
        </Alert>
      </div>
    )}
      <div style={availableEvents.length === 0 ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>

    <BackgroundContainer>
      <HelpButton/>
          <div style={{marginTop:"50px",marginBottom:"50px", width:"90vw"}} className="form-menu-container">

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
  
      <ThemeProvider theme={theme}>
      <CssBaseline />

        <StyledCard>
        <Box sx={{ position: 'absolute', right: 16, top: 16 }}> {/* Positioning the ReportProblem component */}
    <Tooltip title="Report a Problem">
    <IconButton
  color="inherit"
  aria-label="notifications"
  sx={{
    mr: 2,
    zIndex: 1200, // Ensures the menu appears on top of other elements
  }}
>
<ReportProblem dynamicNumber="EDU Gate Stage" />
          </IconButton>
        </Tooltip>
        </Box>
        <Box sx={{ textAlign: 'center', mt: 4 }}>

        <StyledTitle variant="h5" gutterBottom>
      EDU GATE Stage
    </StyledTitle>
    </Box>
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
              <form encType="multipart/form-data" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth>
                      <InputLabel>Select Event</InputLabel>
                      <Select
                        label="Select Event"
                        value={event}
                        onChange={(e) => setEvent(e.target.value)}
                        name="selectedEvent"
                        fullWidth
                        required
                      >
                        {availableEvents.map((event) => (
                          <MenuItem key={event.id} value={event.id}>
                            {event.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Speaker Name"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Grid>
  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="University / Company Name"
                      fullWidth
                      value={university}
                      onChange={(e) => setUniversity(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Grid>
  
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="phone-code-autocomplete"
                      sx={{ flex: 1 }}
                      options={phoneCodes}
                      required
                      autoHighlight
                      getOptionLabel={(option) => `${option.phone}`}
                      filterOptions={filterOptions}
                      value={selectedPhoneCode}
                      onChange={handlePhoneCodeChange}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ display: 'flex', alignItems: 'center', p: 1 }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt={`${option.label} flag`}
                            style={{ marginRight: 8 }}
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country Code"
                          variant="outlined"
                          fullWidth
                          required
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                                      </Grid>

                    <Grid item xs={12} sm={6}>
                    <Tooltip title="Tip: For non-Egyptian numbers, ensure it supports WhatsApp.">

                    <TextField
                      label="Phone Number"
                      fullWidth
                      value={phone}
                      type="tel"
                      inputProps={{ pattern: '[0-9]*' }}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </Tooltip>

                   

                  </Grid>
  
                  
  
                  <Grid item xs={12} sm={6}>
                    <DesktopDatePicker
                      required
                      sx={{ width: '100%' }}
                      textFieldStyle={{ width: '100%' }}
                      fullWidth={true}
                      label="Choose a date"
                      inputFormat="MM/dd/yyyy"
                      value={date}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth required />
                      )}
                      shouldDisableDate={isDateDisabled}
                    />
                  </Grid>
  
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Available Slots</InputLabel>
                      <Select label="Available Slots" value={slot} onChange={handleSlotChange}>
                        {availableSlots.map((slot, index) => (
                          <MenuItem key={index} value={slot}>
                            {slot}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
  
                  <Grid item xs={12}>
                    <TextField
                      label="Topic"
                      fullWidth
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Grid>
  
                  <Grid item xs={12} >

                  <Button
    variant="contained"
    component="label"
    startIcon={<CloudUploadIcon />}
    fullWidth
    sx={{
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      color: 'white',
      fontSize: '16px',
      padding: '12px 20px',
      borderRadius: '30px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }}
  >
                    <input
                      accept="application/pdf"
                      style={{ display: 'none' }}
                      id="upload-presentation"
                      type="file"
                      onChange={handlePresentationUpload}
                    />
                        Upload Presentation
                      </Button>
                    {presentation && <Typography sx={{ marginTop: '8px', fontStyle: 'italic', color: '#555' }}>{presentation.name}</Typography>}
                  </Grid>
  
                  <Grid item xs={12} >
                  <Button
    variant="contained"
    component="label"
    startIcon={<CloudUploadIcon />}
    fullWidth
    sx={{
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      color: 'white',
      fontSize: '16px',
      padding: '12px 20px',
      borderRadius: '30px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }}
  >
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="upload-photo"
                      type="file"
                      onChange={handlePhotoUpload}
                    />
                   
                        Upload Photo
                      </Button>
                    {photo && <Typography sx={{ marginTop: '8px', fontStyle: 'italic', color: '#555' }}>{photo.name}</Typography>}
                  </Grid>
  
                  <Grid item xs={12}>
                    {feedback.message && (
                      <Typography
                        variant="body1"
                        style={{
                          color: feedback.type === 'error' ? 'red' : 'green',
                        }}
                      >
                        {feedback.message}
                      </Typography>
                    )}
                    <StyledButton
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Book Slot
                    </StyledButton>
                    {progress && (
                      <Box sx={{ m:1,width: '100%' }}>
                        <LinearProgress />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </form>
            </LocalizationProvider>
          </CardContent>
        </StyledCard>
      </ThemeProvider>
  
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleclose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Form for Exhibitors only!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This form is not for students. If you are a student, please register{' '}
            <a
              href="/"
              style={{ color: 'black', textDecoration: 'underline' }}
            >
              here
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleclose}>Proceed if Exhibitor</Button>
        </DialogActions>
      </Dialog>
      </div>
    </BackgroundContainer>
    </div>
    </div>

  );
}  

export default BookingForm;
