import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  IconButton,
  FormControl,
  Button,
  Modal,
  Box,
  TextField,
  Collapse,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Alert,
  LinearProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AddServiceModal from './AddServiceModal'; // Import the AddServiceModal component
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import Autocomplete from '@mui/material/Autocomplete';
import { InputLabel, Select, MenuItem } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0033A1"
    },
    secondary: {
      main: "#0033A1"
    }
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StudentServices = () => {
  const [news, setNews] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialNews, setInitialNews] = useState(null); // New state to hold initial event data
  const [photo, setPhoto] = useState(null);
  const [progress, setProgress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");
  const [countries, setCountries] = useState([]);
  const [typeFilter, setTypeFilter] = useState('');
  const [countryFilter, setCountryFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');




  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setCountries(response.data[5].countries);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);

  const handleCommentClick = (comment) => {
    setSelectedComment(comment);
    setOpenModal(true);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!validFileTypes.includes(file.type)) {
        setMessage("Please upload a valid file (JPEG, PNG, or PDF)");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }
  
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }
  
      // Check if it's a PDF
      if (file.type === 'application/pdf') {
        const extension = file.name.split('.').pop();
        const newName = `edugate_contract_${Date.now()}.${extension}`;
        const renamedFile = new File([file], newName, { type: file.type });
        setPhoto(renamedFile);
      } else {
        // If it's an image, perform image-specific validation
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            // File is a valid image
            const extension = file.name.split('.').pop();
            const newName = `edugate_contract_${Date.now()}.${extension}`;
            const renamedFile = new File([file], newName, { type: file.type });
            setPhoto(renamedFile);
          };
          img.onerror = () => {
            setMessage("The uploaded file is not a valid image or is corrupted");
            setSeverity("error");
            setAlertOpen(true);
            setPhoto(null);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };
  

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setProgress(true);
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getservices',{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

      }
      });
      setNews(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching news: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = (newsItem) => {
    setCurrentNews(newsItem);
    setInitialNews(newsItem);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentNews(null);
    setInitialNews(null);
    setPhoto(null);
    setEditOpen(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedComment("");
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const hasChanged = Object.keys(initialNews).some(
      (key) => initialNews[key] !== currentNews[key]
    );
    if (hasChanged || photo) {
      setProgress(true);
      try {
        ///
        const { _id, ...newNewsWithoutId } = currentNews; // Destructure to omit _id
newNewsWithoutId.photo = photo; // Add the photo field to the new object

const token = localStorage.getItem('token');  // Retrieve the token from localStorage

if (!token) {
  // Handle missing token (e.g., redirect to login page)
  console.error('No token found');
  return;
}

await axios.put(
  `https://edutech-backend-471f7cb69116.herokuapp.com/api/updateservice/${_id}`, // Keep _id only in URL
  newNewsWithoutId, // Send the updated news without _id
  {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
    },
  }
);

        setPhoto(null);
        fetchNews();
        setMessage("Provider updated successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating Provider: " + error.message);
        setSeverity("error");
        setAlertOpen(true);
        handleEditClose();
      }
      setProgress(false);
    } else {
      handleEditClose();
    }
  };


  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const filteredTalents = news.filter((talent) => {
    return (
      (statusFilter === '' || talent.status === statusFilter) &&
      (typeFilter === '' || talent.type === typeFilter)&&
      (countryFilter === '' || talent.country === countryFilter)

    );
  });
  
  
  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'country', headerName: 'Country', width: 150 },
    { field: 'contactPerson', headerName: 'Contact Person', width: 150 },
    { field: 'title', headerName: 'Title', width: 150 },
    { field: 'type', headerName: 'Type', width: 150 },
    { field: 'comment', headerName: 'Comment', width: 300,
    renderCell: (params) => {
      const comments = params.value;
  
      // Check if the field is not empty
      if (comments && comments.trim() !== "") {
        return (
          <IconButton
            onClick={() => handleCommentClick(comments)} 
            variant="outlined"
          >
            <CommentIcon />
          </IconButton>
        );
      }
  
      return null;
    }
     },
     {
      field: 'link',
      headerName: 'Website',
      width: 100,
      renderCell: (params) => {
        const link = params.value;
    
        // Check if the field is not empty
        if (link && link.trim() !== "") {
          // Add https:// if not present
          const formattedLink = link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    
          return (
            <a href={formattedLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <IconButton>
                <LinkIcon />
              </IconButton>
            </a>
          );
        }
    
        return null;
      }
    },
    
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        params.row.status === 'not_done' ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          <CheckCircleIcon style={{ color: 'green' }} />
        )
      ),
    },
    {
      field: 'actions',
      headerName: 'Tools',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditOpen(params.row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: 'contract',
      headerName: 'Contract',
      width: 100,
      renderCell: (params) => {
        const comments = params.value;
        if (comments && comments.trim() !== "") {
          return (
            <IconButton href={params.value} target="_blank" rel="noopener noreferrer">
              <LinkIcon />
            </IconButton>
          );
        }
        return null;
      }
    }
  ];
  
    
  

  return (
<ThemeProvider theme={theme}>

     <Box sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>

      <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Status</InputLabel>
              <Select
                label="Filter by Status"
                value={statusFilter}
                onChange={handleFilterChange(setStatusFilter)}

              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="done">Done</MenuItem>
                      <MenuItem value="not_done">Not Done</MenuItem>
              </Select>
            </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
  <FormControl variant="outlined" fullWidth>
    <InputLabel>Filter by Type</InputLabel>
    <Select
      label="Filter by Type"
      value={typeFilter}
      onChange={handleFilterChange(setTypeFilter)}

    >
      <MenuItem value="">All</MenuItem>
      <MenuItem value="Aggregator">Aggregator</MenuItem>
      <MenuItem value="University">University</MenuItem>
      <MenuItem value="Student Services">Student Services</MenuItem>
    </Select>
  </FormControl>
</Grid>

<Grid item xs={12} sm={6} md={3}>
<Autocomplete
  id="country-select-demo"
  fullWidth
  options={countries}
  autoHighlight
  getOptionLabel={(option) => option.label}
  value={countries.find((c) => c.label === countryFilter) || null}

  onChange={(event, newValue) => {
    handleFilterChange(setCountryFilter(newValue ? newValue.label : ""))

  }}
  inputValue={countryFilter || ''}
  renderOption={(props, option) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} 
        </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Filter by Country"
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
      name="country"
    />
  )}
/>
</Grid>   


        </Grid>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      
      <Button sx={{ mt: 1, mb: 1 }} variant="contained" color="primary" onClick={handleOpen}>
        Add Service Provider
      </Button>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={filteredTalents}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
        />
      </div>
      {currentNews && (
      <Modal
      open={editOpen}
      onClose={handleEditClose}
      aria-labelledby="edit-news-title"
      aria-describedby="edit-news-description"
      sx={{ zIndex: 1300 }} // Ensure the modal is above other content
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          maxHeight: '85%',
        }}
      >
        <Typography
          id="edit-news-title"
          variant="h5"
          component="h2"
          sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
        >
          Edit
        </Typography>
    
        <form onSubmit={handleEditSubmit}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Name"
              value={currentNews.name}
              onChange={(e) => setCurrentNews({ ...currentNews, name: e.target.value })}
              fullWidth
              required
            />
          </FormControl>
    
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Website"
              value={currentNews.link}
              onChange={(e) => setCurrentNews({ ...currentNews, link: e.target.value })}
              fullWidth
              required
            />
          </FormControl>
    
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Email"
              value={currentNews.email}
              onChange={(e) => setCurrentNews({ ...currentNews, email: e.target.value })}
              fullWidth
              required
              type="email"
            />
          </FormControl>
    
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Mobile"
              value={currentNews.mobile}
              onChange={(e) => setCurrentNews({ ...currentNews, mobile: e.target.value })}
              fullWidth
              required
              type="number"
            />
          </FormControl>
    
          <Autocomplete
            id="country-select-demo"
            fullWidth
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            value={countries.find((c) => c.label === currentNews.country) || null}
            onChange={(event, newValue) => {
              setCurrentNews({
                ...currentNews,
                country: newValue ? newValue.label : null, // Set to null if no selection
              });
            }}
            inputValue={currentNews.country || ''}
            onInputChange={(event, newInputValue) => {
              setCurrentNews({
                ...currentNews,
                country: newInputValue,
              });
            }}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                name="country"
                required
              />
            )}
            sx={{ mt: 2 }}
          />
    
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Contact Person"
              value={currentNews.contactPerson}
              onChange={(e) => setCurrentNews({ ...currentNews, contactPerson: e.target.value })}
              fullWidth
              required
            />
          </FormControl>
    
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Title"
              value={currentNews.title}
              onChange={(e) => setCurrentNews({ ...currentNews, title: e.target.value })}
              fullWidth
              required
            />
          </FormControl>
  <FormControl fullWidth margin="normal" variant="outlined">
    <TextField
      label="Contract"
      value={currentNews.contract}
      fullWidth
      disabled
    />
  </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Comment"
              value={currentNews.comment}
              onChange={(e) => setCurrentNews({ ...currentNews, comment: e.target.value })}
              fullWidth
              multiline
              rows={4}
            />
          </FormControl>
    
          <FormControl required variant="outlined" fullWidth margin="normal">
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              value={currentNews?.type || ''}
              onChange={(e) => setCurrentNews({ ...currentNews, type: e.target.value })}
            >
              <MenuItem value="Aggregator">Aggregator</MenuItem>
              <MenuItem value="University">University</MenuItem>
              <MenuItem value="Student Services">Student Services</MenuItem>
            </Select>
          </FormControl>
    
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={currentNews?.status || ''}
              onChange={(e) => setCurrentNews({ ...currentNews, status: e.target.value })}
            >
              <MenuItem value="done">Done</MenuItem>
              <MenuItem value="not_done">Not Done</MenuItem>
            </Select>
          </FormControl>
    
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12}>
              <input
                accept="image/*,application/pdf" // Accepts images and PDFs
                style={{ display: 'none' }}
                id="upload-photo"
                type="file"
                onChange={handlePhotoUpload}
              />
              <label htmlFor="upload-photo">
                <Button
                  startIcon={<CloudUploadIcon />}
                  variant="outlined"
                  component="span"
                  fullWidth
                  sx={{
                    mt: 2,
                    py: 1.5,
                    fontWeight: 'bold',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  }}
                >
                  Change Contract
                </Button>
              </label>
              {photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>}
            </Grid>
          </Grid>
    
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 3,
              py: 1.5,
              fontWeight: 'bold',
              backgroundColor: '#0033A1 !important',
              '&:hover': {
                backgroundColor: '#002080 !important',
              },
            }}          >
            Save Changes
          </Button>
    
          {progress && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress />
            </Box>
          )}
        </form>
      </Box>
    </Modal>
    
      
      )}
      <AddServiceModal
        open={open}
        handleClose={handleClose}
        fetchNews={fetchNews}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        setSeverity={setSeverity}
      />
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedComment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      </ThemeProvider>
  );
};

export default StudentServices;
