import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import phoneCodes from '../phoneCodes'; // Import your phone codes data here
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ReportProblem from '../ReportProblem';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import HelpButton from '../HelpButton';
import Header from '../Header';
import Footer from '../Footer';
const apiKey = process.env.REACT_APP_API_KEY;

const formContainerStyles = {
  padding: (theme) => theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.8)',
  borderRadius: (theme) => theme.spacing(1),
  marginBottom: (theme) => theme.spacing(3),
  marginTop: (theme) => theme.spacing(3),

};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    },
  }
});



const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: (theme) => theme.spacing(2),
  padding: (theme) => theme.spacing(2),
};

const buttonStyles = {
  marginTop: (theme) => theme.spacing(2),
};

const convertArabicToEnglish = (input) => {
  const arabicNumbers = '٠١٢٣٤٥٦٧٨٩';
  const englishNumbers = '0123456789';
  let output = '';
  for (let i = 0; i < input.length; i++) {
    const index = arabicNumbers.indexOf(input[i]);
    output += index !== -1 ? englishNumbers[index] : input[i];
  }
  return output;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

var currentdate = new Date();
var datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    Corporate_Name: "",
    Contact_person: "",
    email: "",
    mobile: "",
    Country: "",
    city: "",
    Organization_website_or_Facebook_page: '',
    Kind_of_Participation: '',
    Kind_of_Participation_other: "",
    howDidYouKnow: '',
    status: "0",
    whichEvent: "",
    date: datetime,
    countryCode: '', // Initialize country code
  });
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label,
  });

  const [availableEvents, setAvailableEvents] = useState([]);
  const [progress, setprogress] = useState(false);
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [language, setLanguage] = useState('en');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState(null); // State for phone code
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(true);
  const [countries, setCountries] = useState([]);

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setAvailableEvents(filteredEvents);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchAvailableEvents();
  }, []);

  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setCountries(response.data[5].countries);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);


  const [formStates, setFormStates] = useState(null);
  useEffect(() => {
    fetchFormStates();
  }, []);
  
  
  const fetchFormStates = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/formstates');
      setFormStates(response.data.Edugate.Enquiry);
    } catch (error) {
      console.error('Error fetching form states', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newValue = value;

    if (name === 'Kind_of_Participation') {
      setIsOtherSelected(value === 'Other');
    }

    if (name === 'Corporate_Name' || name === 'Contact_person') {
      newValue = capitalizeFirstLetter(newValue);
    }

    const convertedValue = convertArabicToEnglish(newValue);
    setFormData({ ...formData, [name]: convertedValue });
  };

  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
    setFormData({
      ...formData,
      Country: newValue ? newValue.label : '',
    });
  };
  const handlePhoneCodeChange = (event, newValue) => {
    setSelectedPhoneCode(newValue);
    setFormData({
      ...formData,
      countryCode: newValue ? newValue.phone : '',
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setprogress(true);

    try {
      let formData2={
        ...formData,
        mobile: "+"+formData.countryCode+formData.mobile,

      }
      const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/enquire_edugate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData2),
      });

      if (response.status === 201) {
        setFeedback({
          message: translations[language].successMessage,
          type: 'success',
        });
      } else if (response.status === 400) {
        setIsSubmitting(false);
        setFeedback({
          message: translations[language].alreadyMessage,
          type: 'error',
        });
      } else {
        setFeedback({
          message: translations[language].errorMessage,
          type: 'error',
        });
      }
    } catch (error) {
      setFeedback({
        message: translations[language].errorMessage,
        type: 'error',
      });
    }
    setprogress(false);
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const translations = {
    en: {
      Language: "عربي",
      other: "Specify Other",
      phoneMessage: "Phone number must be exactly 11 digits.",
      registrationForm: "Registration Form",
      firstNameLabel: "First Name",
      middleNameLabel: "Middle Name",
      lastNameLabel: "Last Name",
      emailLabel: "Email",
      mobileLabel: "Mobile",
      schoolNameLabel: "School Name",
      eduLevelLabel: "Educational Level",
      studyFieldLabel: "Intended Studying Field",
      howDidYouKnowLabel: "How Did You Know About Us",
      submitButtonLabel: "Submit",
      successMessage: "Thank you for choosing EDU GATE! Your info was received successfully ",
      errorMessage: "An error occurred while processing the form.",
      alreadyMessage: "You have already registered with this Email",
      eduLevelOptions: [
        { value: 'Preparatory', label: 'Preparatory' },
        { value: 'Technical Secondary School', label: 'Technical Secondary School' },
        { value: 'Graduate', label: 'Graduate' },
      ],
      studyFieldOptions: [
        { value: 'Platinum Sponsor', label: 'Platinum Sponsor' },
        { value: 'Gold Sponsor', label: 'Gold Sponsor' },
        { value: 'Silver Sponsor', label: 'Silver Sponsor' },
        { value: 'Exhibitor', label: 'Exhibitor' },
        { value: 'Other', label: 'Other' },
      ],
      howDidYouKnowOptions: [
        { value: 'Google Search', label: 'Google Search' },
        { value: 'SMS', label: 'SMS' },
        { value: 'Email', label: 'Email' },
        { value: 'Radio', label: 'Radio' },
        { value: 'Facebook', label: 'Facebook' },
        { value: 'Instagram', label: 'Instagram' },
        { value: 'Whatsapp', label: 'Whatsapp' },
        { value: 'Website', label: 'Website' },
        { value: 'Friends', label: 'Friends' },
        { value: 'Other', label: 'Other' },
      ],
    },
  };
  const handleclose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <div>
    <Header/>

    {formStates && (
            <div style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
              width: '400px'
            }}>
              <Alert 
                icon={<CheckIcon fontSize="inherit" />} 
                severity="error"
                style={{ display: 'flex', alignItems: 'center' }}
              >
    Enquiry Form is Currently Closed.          
    </Alert>
            </div>
          )}
    
    <div style={formStates ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>
          <div
        style={{
          background: `url('https://edugate-eg.com/wp-content/uploads/2023/08/8O0A0068_result-e1712786425854.webp')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <HelpButton/>
        <Container component="main" maxWidth="sm">
          <Paper style={{position:"relative"}} elevation={3} sx={formContainerStyles}>
          <Box sx={{ position: 'absolute', right: 16, top: 16 }}> {/* Positioning the ReportProblem component */}
    <Tooltip title="Report a Problem">
    <IconButton
  color="inherit"
  aria-label="notifications"
  sx={{
    mr: 2,
    zIndex: 1200, // Ensures the menu appears on top of other elements
  }}
>
<ReportProblem dynamicNumber="Enquiry Eduagte" />
          </IconButton>
        </Tooltip>
        </Box>
           
            <Box sx={{ textAlign: 'center', mt: 4 }}>

<StyledTitle variant="h5" gutterBottom>
Enquire Form
</StyledTitle>
</Box>
            <ThemeProvider theme={theme}>
              <form onSubmit={handleSubmit}>

                <Grid mb={2} item xs={12} sm={6}>
                <FormControl sx={{mt:2}} required fullWidth>
                <InputLabel>Select Event</InputLabel>
                <Select
                required
                
                label="Select Event"
                  value={formData.whichEvent}
                  onChange={handleInputChange}
                  name="whichEvent"
                  fullWidth
                >
                  {availableEvents.map((event) => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                </Grid>

                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                  <TextField
                    label="Corporate Name"
                    name="Corporate_Name"
                    value={formData.Corporate_Name}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="Contact Person"
                    name="Contact_person"
                    value={formData.Contact_person}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <Autocomplete
  id="phone-code-autocomplete"
  sx={{ flex: 1 }}
  options={phoneCodes}
  required
  autoHighlight
  getOptionLabel={(option) => `${option.phone}`}
  filterOptions={filterOptions} // Add this line to customize filtering
  value={selectedPhoneCode}
  onChange={handlePhoneCodeChange}
  renderOption={(props, option) => (
    <Box
      component="li"
      sx={{ display: 'flex', alignItems: 'center', p: 1 }}
      {...props}
    >
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        alt={`${option.label} flag`}
        style={{ marginRight: 8 }}
      />
      {option.label} ({option.code}) +{option.phone}
    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Country Code"
      variant="outlined"
      fullWidth
      required
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password', // disable autocomplete and autofill
      }}
    />
  )}
/>
                  <TextField
                    label={translations[language].mobileLabel}
                    name="mobile"
                    value={formData.mobile.replace(/^\D+/g, '')} // Strip non-digits for input
                    onChange={(e) => setFormData({
                      ...formData,
                      mobile: e.target.value
                    })}
                    required
                    sx={{ flex: 1 }}
                    type="tel"
                    inputProps={{ pattern: '[0-9]*' }}
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <Autocomplete
    id="country-select-demo"
    sx={{ flex: 1 }}
    options={countries}
    autoHighlight
    required
    getOptionLabel={(option) => option.label}
    value={selectedCountry}
    onChange={handleCountryChange}
    renderOption={(props, option) => {
      const { key, ...optionProps } = props;
      return (
        <Box
          key={key}
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...optionProps}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt={`${option.label} flag`}
          />
          {option.label}
        </Box>
      );
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Choose a country"
        variant="outlined"
        fullWidth
        required
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    )}
  />
                  <TextField
                    label={translations[language].emailLabel}
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                  
                </Box>

                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                  <TextField
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                  <TextField
                    label="Organization website or Facebook page"
                    name="Organization_website_or_Facebook_page"
                    value={formData.Organization_website_or_Facebook_page}
                    onChange={handleChange}
                    required
                    sx={{ flex: 1 }}
                  />
                </Box>

                <FormControl required fullWidth sx={{ marginBottom: '20px' }}>
                  <InputLabel id="Kind_of_Participation-label">
                    Kind of Participation
                  </InputLabel>
                  <Select
                    labelId="Kind_of_Participation-label"
                    id="Kind_of_Participation"
                    name="Kind_of_Participation"
                    value={formData.Kind_of_Participation}
                    onChange={handleChange}
                    required
                    label="Kind of Participation"
                  >
                    {translations[language].studyFieldOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {isOtherSelected && (
                    <TextField
                      label="Specify"
                      name="Kind_of_Participation_other"
                      value={formData.Kind_of_Participation_other}
                      onChange={handleChange}
                      sx={{ m: 1 }}
                      required
                    />
                  )}
                </FormControl>

                <FormControl required fullWidth sx={{ marginBottom: '20px' }}>
                  <InputLabel id="howDidYouKnow-label">
                    How did you hear about us?
                  </InputLabel>
                  <Select
                    labelId="howDidYouKnow-label"
                    id="howDidYouKnow"
                    name="howDidYouKnow"
                    value={formData.howDidYouKnow}
                    onChange={handleChange}
                    required
                    label={translations[language].howDidYouKnowLabel}
                  >
                    {translations[language].howDidYouKnowOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={buttonStyles}
                  disabled={isSubmitting || formStates}
                >
                  {translations[language].submitButtonLabel}
                </Button>

                {progress && (
                  <Box sx={{ width: '100%',m:1 }}>
                    <LinearProgress />
                  </Box>
                )}
                {feedback.message && (
                  <Typography
                    variant="body1"
                    style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                  >
                    {feedback.message}
                  </Typography>
                )}
              </form>
            </ThemeProvider>
          </Paper>
        </Container>
        <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleclose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Form for Exhibitors only!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          This form is not for students. If you are a student, please register 
              <a href="/" style={{ color: 'black', textDecoration: 'underline' }}> here</a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleclose}>
            Proceed if Exhibitor
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default RegistrationForm;
