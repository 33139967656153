import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, CardContent, Typography, Box, CircularProgress, Pagination } from '@mui/material';
import { styled } from '@mui/system';

// Styled Components
const SponsorsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  width: '100%', // Make the card take the full width of the container
  boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)',
  },
  borderTop: '5px solid #C8102E',
}));



const StyledTitle = styled(Typography)({
  fontFamily: '"Poppins", sans-serif',
  fontWeight: 600,
  fontSize: 'clamp(2rem, 4vw, 2.5rem)', // Responsive font size
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  marginBottom: '10px',
  textAlign: 'center',  // Align text to center horizontally

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-4px',
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#C8102E',
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },

  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
});

const GradientLine = styled('div')({
  height: '4px',
  width: '120px',
  background: 'linear-gradient(90deg, #C8102E, #000000)',
  margin: '10px auto 30px',
  borderRadius: '2px',
});


const Container = styled('section')({
  backgroundColor: '#ffffff',
  color: '#333',
  // padding: '10px 10px',
  maxWidth: '98vw',
  margin: 'auto',
  textAlign: 'center',
  borderRadius: '20px',
  boxShadow: '0 15px 40px rgba(0, 0, 0, 0.1)',
  marginTop: '50px',
});
const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [sponsorsPerPage] = useState(6); // Number of sponsors per page

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await axios.get("https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent");
        setEvents(data.filter(event => event.deleted === "0" && event.is_school === "0"));
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    fetchEvents();
  }, []);


  

  const fetchSponsors = async () => {
    setLoading(true);
    setError(null);

    try {
      

      const offersResponse = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getoffers',{
        headers: {
          'Content-Type': 'application/json',
        },
      });
      let offersData = offersResponse.data.map(offer => ({
        ...offer,
        event_ids: offer.event_ids ? offer.event_ids.toString().split(',').map(id => Number(id)) : []
      }));
       offersData = offersData.filter(offer => offer.deleted === "0");
       const availableEventIds = events.map(event => event.id);


       const filteredOffers = offersData.filter(offer => {
        const offerEventIds = offer.event_ids;
        return offer.deleted === "0" && offerEventIds.some(id => availableEventIds.includes(id));
      });
  

      setSponsors(filteredOffers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching sponsors:', error);
      setError('Failed to load sponsors');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (events.length > 0) {
      fetchSponsors();
    }
  }, [events]);
  

  // Get sponsors for the current page
  const indexOfLastSponsor = currentPage * sponsorsPerPage;
  const indexOfFirstSponsor = indexOfLastSponsor - sponsorsPerPage;
  const currentSponsors = sponsors.slice(indexOfFirstSponsor, indexOfLastSponsor);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Auto-scroll to the top of the sponsors section when the page changes
  useEffect(() => {
    const element = document.getElementById('offers-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [currentPage]);

  if (loading) {
    return (
      <SponsorsContainer>
        <CircularProgress />
      </SponsorsContainer>
    );
  }

  if (error) {
    return (
      <SponsorsContainer>
        <Typography variant="h6" color="error">{error}</Typography>
      </SponsorsContainer>
    );
  }

  return (
    <Container>
          <StyledTitle>Don't Miss The Offers </StyledTitle>
          <GradientLine></GradientLine>

    <SponsorsContainer id="offers-section">
      <Grid container spacing={4} justifyContent="center">
        {currentSponsors.map((sponsor, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <CardContent>
                <img src={sponsor.image} alt={sponsor.title} style={{ width: '100%', height: '300px' }} />
                {/* <Typography variant="h6" align="center">{sponsor.title.toUpperCase()}</Typography> */}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      
      {/* Pagination */}
      <Pagination
        count={Math.ceil(sponsors.length / sponsorsPerPage)} // Calculate total number of pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        sx={{ marginTop: 4 }}
      />
    </SponsorsContainer>
    </Container>
  );
};

export default Sponsors;
