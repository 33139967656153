import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl,Grid, InputLabel, Box, Chip, Checkbox, ListItemText, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import * as XLSX from 'xlsx';
import LinearProgress from '@mui/material/LinearProgress';
import moment from"moment";

const VisitorFilterPage = () => {
  // State to manage selected filters
  const [progress, setprogress] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [isStudyingAbroadFilter, setIsStudyingAbroadFilter] = useState('');
  const [destinationFilter, setDestinationFilter] = useState('');
  const [studyFieldFilter, setStudyFieldFilter] = useState('');
  const [ageMin, setAgeMin] = useState('');
  const [ageMax, setAgeMax] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');


  // State for loading data
  const [availableEvents, setAvailableEvents] = useState([]);
  const [uniqueDestinations, setUniqueDestinations] = useState([]);
  const [uniqueStudyFields, setUniqueStudyFields] = useState([]);
  const [cities, setCities] = useState([]);

  const [filteredVisitors, setFilteredVisitors] = useState([]);
  
  // Load available events and filter data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch list of events
        const eventsResponse = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
        setAvailableEvents(eventsResponse.data.reverse());
        const destinationsResponse =  await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
        setUniqueDestinations(destinationsResponse.data[5].countries);
        setCities(destinationsResponse.data[2].governoratesInEgypt);
        setUniqueStudyFields(destinationsResponse.data[3].desiredFields);

      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  const handleFilterSubmit = async () => {
    try {
      setprogress(true);
  
      const queryParams = {
        education_type: selectedEducation.join(','),
        grade: selectedGrades.join(','),
        visitor_type: selectedUserType.join(','),
        study_abroad: isStudyingAbroadFilter,
        destination: destinationFilter,
        study_field: studyFieldFilter,
        gender: gender,
        country: country,
        city: city,
      };
  
      const token = localStorage.getItem('token'); // Retrieve the token from localStorage
      if (!token) {
        console.error('No token found');
        setprogress(false);
        return;
      }
  
      try {
        // Fetch all data in a single request
        const response = await axios.get(
          `https://edugate-backend-b5ad4c02888d.herokuapp.com/api/${selectedEvent}/filter/visitors`,
          {
            params: queryParams,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        // Filter data based on age_min and age_max
        const filteredData = response.data.filter((visitor) => {
          const birthdate = parseBirthdate(visitor.birth_date); // Assuming `birthdate` field exists in the response
          const age = calculateAge(birthdate);
          const ageMinValid = ageMin ? age >= ageMin : true; // Check if age meets minimum requirement
          const ageMaxValid = ageMax ? age <= ageMax : true; // Check if age meets maximum requirement
          return ageMinValid && ageMaxValid;
        });
  
        // Set the filtered visitors in the state
        setFilteredVisitors(filteredData);
  
        if (filteredData && filteredData.length > 0) {
          const ws = XLSX.utils.json_to_sheet(filteredData); // Convert the filtered data to a sheet
          const wb = XLSX.utils.book_new(); // Create a new workbook
          XLSX.utils.book_append_sheet(wb, ws, 'Filtered Visitors'); // Append the sheet to the workbook
  
          // Export the Excel file with a custom file name
          XLSX.writeFile(wb, 'filtered_visitors.xlsx');
        } else {
          console.log('No visitors found to export.');
        }
      } catch (error) {
        console.error('Error fetching visitors:', error);
      }
    } catch (error) {
      console.error('Error fetching filtered visitors:', error);
    } finally {
      setprogress(false); // Ensure progress is turned off when done
    }
  };
  
  // Helper functions
  const parseBirthdate = (dateStr) => {
    const formats = ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DDTHH:mm:ss'];
    for (const fmt of formats) {
      const parsed = moment(dateStr, fmt, true);
      if (parsed.isValid()) {
        return parsed.toDate();
      }
    }
    return null;
  };
  
  const calculateAge = (birthdate) => {
    if (!birthdate) return null;
    const today = moment();
    const birth = moment(birthdate);
    return today.diff(birth, 'years');
  };
  
  

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 3 }}>
      {/* Event Selection */}
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main', letterSpacing: 1, textAlign: 'center' }}>
  Export System
</Typography>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
      <FormControl required fullWidth variant="outlined" sx={{boxShadow: 1, borderRadius: 1 }}>
        <InputLabel>Choose Event</InputLabel>
        <Select
          value={selectedEvent}
          onChange={(e) => setSelectedEvent(e.target.value)}
          label="Choose Event"
          sx={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          <MenuItem key="all" value="all" >All</MenuItem>
          {availableEvents.map((event) => (
            <MenuItem key={event.id} value={event.id}>
              {event.name} ({event.start_date})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
      {/* Education Type Filter */}
      <Grid item xs={12} sm={4}>
      <FormControl variant="outlined"  fullWidth sx={{ boxShadow: 1, borderRadius: 1 }}>
        <InputLabel>Education Type</InputLabel>
        <Select
          multiple
          value={selectedEducation}
          onChange={(e) => setSelectedEducation(e.target.value)}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => <Chip key={value} label={value} />)}
            </Box>
          )}
          label="Education Type"
          sx={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          {[
            "American Diploma", "IGCSE Diploma", "Thanaweya Amma", "Canadian Diploma",
            "International Baccalaureate Diploma", "French Baccalaureate Diploma", "German Abitur",
            "BTEC", "WAEC", "Nile Certificate (Cambridge)", "International Education System",
            "Foreign Certificate", "Other"
          ].map((type) => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={selectedEducation.includes(type)} />
              <ListItemText primary={type} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
      <FormControl variant="outlined" fullWidth sx={{  boxShadow: 1, borderRadius: 1 }}>
        <InputLabel>Grade</InputLabel>
        <Select
          multiple
          value={selectedGrades}
          onChange={(e) => setSelectedGrades(e.target.value)}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => <Chip key={value} label={value} sx={{ borderRadius: 1, padding: '4px 8px' }} />)}
            </Box>
          )}
          label="Grade"
          sx={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          {["Grade 10", "Grade 11", "Grade 12"].map((grade) => (
            <MenuItem key={grade} value={grade}>
              <Checkbox checked={selectedGrades.includes(grade)} />
              <ListItemText primary={grade} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      </Grid>
      <Grid container spacing={2}>

      <Grid item xs={12} sm={4}>
      {/* Grade Filter */}
           <FormControl variant="outlined" fullWidth sx={{boxShadow: 1, borderRadius: 1 }}>
        <InputLabel>Visitor Type</InputLabel>
        <Select
          multiple
          value={selectedUserType}
          onChange={(e) => setSelectedUserType(e.target.value)}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => <Chip key={value} label={value} sx={{ borderRadius: 1, padding: '4px 8px' }} />)}
            </Box>
          )}
          label="Visitor Type"
          sx={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          {[
            { value: "parent", label: "Parent" },
            { value: "postGraduate", label: "PostGraduate" },
            { value: "underGraduate", label: "UnderGraduate" }
          ].map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={selectedUserType.includes(value)} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
      {/* Willingness to Study Abroad Filter */}
      <FormControl variant="outlined" fullWidth sx={{ boxShadow: 1, borderRadius: 1 }}>
        <InputLabel>Willingness to Study Abroad</InputLabel>
        <Select
          label="Willingness to Study Abroad"
          value={isStudyingAbroadFilter}
          onChange={(e) => setIsStudyingAbroadFilter(e.target.value)}
          sx={{ borderRadius: '8px' }}
        >
          <MenuItem value="1">Yes</MenuItem>
          <MenuItem value="0">No</MenuItem>
        </Select>
      </FormControl>
       </Grid>
      {/* Destination Filter */}
      <Grid item xs={12} sm={4}>
      <FormControl variant="outlined" fullWidth sx={{ boxShadow: 1, borderRadius: 1 }}>
        <InputLabel>Destination</InputLabel>
        <Select
          label="Destination"
          value={destinationFilter}
          onChange={(e) => setDestinationFilter(e.target.value)}
          sx={{ borderRadius: '8px' }}
        >
          {uniqueDestinations.map((governorate) => (
            <MenuItem key={governorate.label} value={governorate.label}>
              {governorate.label}
            </MenuItem>
          ))}

        </Select>
      </FormControl>
      </Grid>
      </Grid>
      <Grid container spacing={2}>


      <Grid item xs={12} sm={4}>

      {/* Study Field Filter */}
      <FormControl variant="outlined" fullWidth sx={{  boxShadow: 1, borderRadius: 1 }}>
        <InputLabel>Study Field</InputLabel>
        <Select
          label="Study Field"
          value={studyFieldFilter}
          onChange={(e) => setStudyFieldFilter(e.target.value)}
          sx={{ borderRadius: '8px' }}
        >
          {uniqueStudyFields.map((governorate) => (
            <MenuItem key={governorate.label} value={governorate.label}>
              {governorate.label}
            </MenuItem>
          ))}

        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>

{/* Gender Filter */}
<FormControl variant="outlined" fullWidth sx={{  boxShadow: 1, borderRadius: 1 }}>
  <InputLabel>Gender</InputLabel>
  <Select
    value={gender}
    onChange={(e) => setGender(e.target.value)}
    label="Gender"
    sx={{ borderRadius: '8px' }}
  >
    <MenuItem value="">All</MenuItem>
    <MenuItem value="Male">Male</MenuItem>
    <MenuItem value="Female">Female</MenuItem>
  </Select>
</FormControl>
</Grid>
{/* Country Filter */}
<Grid item xs={12} sm={4}>
<FormControl variant="outlined" fullWidth sx={{  boxShadow: 1, borderRadius: 1 }}>
  <InputLabel>Country</InputLabel>
  <Select
    value={country}
    onChange={(e) => setCountry(e.target.value)}
    label="Country"
    sx={{ borderRadius: '8px' }}
  >
     {uniqueDestinations.map((governorate) => (
            <MenuItem key={governorate.label} value={governorate.label}>
              {governorate.label}
            </MenuItem>
          ))}

  </Select>
</FormControl>
</Grid>        
    </Grid>
    <Grid container spacing={2}>
    <Grid item xs={12} sm={4}>
<FormControl variant="outlined" fullWidth sx={{  boxShadow: 1, borderRadius: 1 }}>
  <InputLabel>City</InputLabel>
  <Select
    value={city}
    onChange={(e) => setCity(e.target.value)}
    label="City"
    sx={{ borderRadius: '8px' }}
  >
     {cities.map((governorate) => (
            <MenuItem key={governorate.label} value={governorate.label}>
              {governorate.label}
            </MenuItem>
          ))}

  </Select>
</FormControl>
</Grid>        

      <Grid item xs={12} sm={4}>
      {/* Age Filters */}
        <TextField
          label="Min Age"
          variant="outlined"
          type="number"
          value={ageMin}
          fullWidth
          onChange={(e) => setAgeMin(e.target.value)}
          sx={{ minWidth: 120 }}
        />
         </Grid>  
         <Grid item xs={12} sm={4}>
        <TextField
          label="Max Age"
          variant="outlined"
          type="number"
          value={ageMax}
          fullWidth
          onChange={(e) => setAgeMax(e.target.value)}
          sx={{ minWidth: 120 }}
        />
      </Grid> 
      </Grid>
      
      {/* Filter Button */}
      {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
      <Button style={{backgroundColor:"green"}} variant="contained" onClick={handleFilterSubmit} sx={{ marginTop: 2 }}>
       Apply Filtes and Export to Excel
      </Button>

      {/* Display filtered visitors */}
      <Box sx={{ marginTop: 3 }}>
        {filteredVisitors.length > 0 ? (
          <Typography variant="h6" color="success.main">
  🎉 {filteredVisitors.length} records exported successfully!
</Typography>
          // filteredVisitors.map((visitor, index) => (
          //   <Box key={index} sx={{ marginBottom: 2, border: '1px solid', padding: 2 }}>
          //     <p>{visitor.name}</p>
          //     <p>{visitor.education_type}</p>
          //     <p>{visitor.grade}</p>
          //   </Box>
           
        ) : (
          <p>No visitors found with the selected filters.</p>
        )}
      </Box>
    </Box>
  );
};

export default VisitorFilterPage;
