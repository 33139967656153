import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardContent, Typography, Box, CircularProgress, Pagination } from '@mui/material';
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';


// Styled Components
const SpeakersContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)',
  },
  borderTop: '5px solid #C8102E',
}));

const StyledTitle = styled(Typography)({
  fontFamily: '"Poppins", sans-serif',
  fontWeight: 600,
  fontSize: 'clamp(2rem, 4vw, 2.5rem)', // Responsive font size
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  textAlign: 'center',
  marginBottom: '10px',
});

const GradientLine = styled('div')({
  height: '4px',
  width: '120px',
  background: 'linear-gradient(90deg, #C8102E, #000000)',
  margin: '10px auto 30px',
  borderRadius: '2px',
});

const Container = styled('section')({
  backgroundColor: '#ffffff',
  color: '#333',
  // padding: '80px 20px',
  maxWidth: '98vw',
  margin: 'auto',
  textAlign: 'center',
  borderRadius: '20px',
  boxShadow: '0 15px 40px rgba(0, 0, 0, 0.1)',
  marginTop: '50px',
});

const SpeakersList = () => {
  const [speakers, setSpeakers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [events, setEvents] = useState([]);

  const speakersPerPage = 6;


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await axios.get("https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent");
        setEvents(data.filter(event => event.deleted === "0" && event.is_school === "0" && event.has_stage ==="1"));
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    fetchEvents();
  }, []);


  useEffect(() => {
    const fetchSpeakers = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/stage_data');
        const allSpeakers = response.data.filter(speaker => speaker.status === "1");
  
        // Ensure event_id is an array and filter speakers based on valid event IDs
        const validSpeakers = allSpeakers.filter(speaker => {
          const eventIds = speaker.event_id ? speaker.event_id.toString().split(',').map(id => Number(id)) : [];
          return eventIds.some(eventId => events.some(event => event.id === eventId));
        });
  
        setSpeakers(validSpeakers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching speakers:', error);
        setError('Failed to load speakers');
        setLoading(false);
      }
    };
    if (events.length > 0) {
        fetchSpeakers();
      }
    }, [events]); // ✅ Added `events` as a dependency

    const sortedSpeakers = [...speakers].sort((a, b) => {
        const dateA = a.date ? new Date(a.date) : new Date(0);
        const dateB = b.date ? new Date(b.date) : new Date(0);
      
        if (dateA - dateB !== 0) return dateA - dateB;
        return a.slot.localeCompare(b.slot);
      });
      
  

  const indexOfLastSpeaker = currentPage * speakersPerPage;
  const indexOfFirstSpeaker = indexOfLastSpeaker - speakersPerPage;
  const currentSpeakers = sortedSpeakers.slice(indexOfFirstSpeaker, indexOfLastSpeaker);






  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) {
    return (
      <SpeakersContainer>
        <CircularProgress />
      </SpeakersContainer>
    );
  }

  if (error) {
    return (
      <SpeakersContainer>
        <Typography variant="h6" color="error">{error}</Typography>
      </SpeakersContainer>
    );
  }

  return (
    <Container>
      <StyledTitle>Our Inspiring Speakers</StyledTitle>
      <GradientLine />
      <SpeakersContainer>
        <Grid container spacing={4} justifyContent="center">
          {currentSpeakers.map((speaker, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard>
                <CardContent>
                  {/* <img src={speaker.photoId} alt={speaker.name} style={{ width: '100%', height: '300px' }} /> */}
                  <Box display="flex" justifyContent="center">
  <Avatar 
    sx={{ 
      width: 150, 
      height: 150, 
      borderRadius: "50%", 
      objectFit: "cover" 
    }} 
    alt={speaker.name || "Speaker"} 
    src={speaker.photoId} 
  />
</Box>

                  <Typography variant="h6" align="center">{speaker.name.toUpperCase()}</Typography>
                  <Typography variant="body2" align="center">{speaker.description}</Typography>
                  <Typography variant="body2" align="center">Date: {speaker.date} | Time: {speaker.slot}</Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={Math.ceil(speakers.length / speakersPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          sx={{ marginTop: 4 }}
        />
      </SpeakersContainer>
    </Container>
  );
};

export default SpeakersList;
