import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Avatar from '@mui/material/Avatar';

import {
  IconButton,Typography,Grid,Button,
  Modal,
  Box,
  Collapse,
  Slide,
  Alert,
  LinearProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import * as XLSX from 'xlsx';  // Import xlsx package for exporting
import InfoIcon from '@mui/icons-material/Info';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000",
    },
    secondary: {
      main: "#FFD700",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [progress, setProgress] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [bioModalOpen, setBioModalOpen] = useState(false);
  const [selectedBio, setSelectedBio] = useState('');
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };
  const handleImageModalClose = () => {
    setSelectedImage("");
    setImageModalOpen(false);
  };
  const handleBioClick = (bio) => {
    setSelectedBio(bio);
    setBioModalOpen(true);
  };

  const handleBioModalClose = () => {
    setSelectedBio("");
    setBioModalOpen(false);
  };
  
  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/Panelist-form');
      setNews(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching Sponsors: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  
 

  const columns = [
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'nameArabic', headerName: 'Arabic Name', width: 300 },
    { field: 'session', headerName: 'Session', width: 300 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'mobile', headerName: 'Mobile', width: 300 },
    { field: 'affiliation', headerName: 'Affiliation', width: 300 },
    { field: 'affiliationArabic', headerName: 'Arabic Affiliation', width: 300 },
    { field: 'bio', headerName: 'Bio', width: 300, renderCell: (params) => (
      <IconButton onClick={() => handleBioClick(params.row?.bio)}>
        <InfoIcon />
      </IconButton>
  ) },
    
    {
        field: 'imageUrl',
        headerName: 'Image',
        width: 150,
        renderCell: (params) => (
          <Avatar
            alt={params.row.name}
            src={params.value}
            sx={{ width: 56, height: 56, cursor: 'pointer' }}
            onClick={() => handleImageClick(params.value)}
          />
        )
      },
  ];
  // Export to Excel function
  const handleExportToExcel = () => {
    const groupedBySession = groupBySession(news);
    const wb = XLSX.utils.book_new();

    Object.keys(groupedBySession).forEach((session, index) => {
      // Generate sheet names like "Session 1", "Session 2", etc.
      const sheetName = `Session ${index + 1}`;
      const data = groupedBySession[session].map(panelist => ({
        Name: panelist.name,
        NameArabic: panelist.nameArabic,
        Session: panelist.session,
        Email: panelist.email,
        Mobile: panelist.mobile,
        Affiliation: panelist.affiliation,
        AffiliationArabic: panelist.affiliationArabic,
        Bio: panelist.bio,
        Image: panelist.imageUrl,
      }));
      
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);  // Add a sheet for each session
    });

    // Export the workbook to an Excel file
    XLSX.writeFile(wb, 'Panelists_Sessions.xlsx');
  };

  // Group data by session
  const groupBySession = (data) => {
    return data.reduce((acc, panelist) => {
      if (!acc[panelist.session]) {
        acc[panelist.session] = [];
      }
      acc[panelist.session].push(panelist);
      return acc;
    }, {});
  };

  return (
    <div>
      <Box sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'right' }}>
            <Button variant="contained" color="success" 
            onClick={handleExportToExcel} 
            sx={{ width: '100%' }}>
              Export to Excel
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={news}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
        />
      </div>
      <Modal
  open={imageModalOpen}
  onClose={handleImageModalClose}
  aria-labelledby="image-modal-title"
  aria-describedby="image-modal-description"
  sx={{ zIndex: 1300 }} // Ensure the modal is above other content
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { xs: '90%', sm: '80%', md: '60%' },
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 2,
      p: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    }}
  >
    <img
      src={selectedImage}
      alt="Product"
      style={{
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 4,
      }}
    />
  </Box>
</Modal>
<Modal
        open={bioModalOpen}
        onClose={handleBioModalClose}
        aria-labelledby="bio-modal-title"
        aria-describedby="bio-modal-description"
        sx={{ zIndex: 1300 }} // Ensure the modal is above other content
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: '60%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Bio Details
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {selectedBio}
          </Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleBioModalClose}
            sx={{ position: 'absolute', top: 10, right: 10 }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Modal>
      
    </div>
  );
};

export default NewsPage;
