import React from 'react';
import { Grid, Card, CardMedia, Box,Typography } from '@mui/material';
import { styled } from '@mui/system';

// Styled Components
const GalleryContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  display: 'flex',
  justifyContent: 'center',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 18,
  overflow: 'hidden',
  boxShadow: '0 12px 28px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.4s ease, box-shadow 0.4s ease, filter 0.3s ease',
  '&:hover': {
    transform: 'scale(1.08)',
    boxShadow: '0 20px 50px rgba(0, 0, 0, 0.3)',
    filter: 'brightness(1.15)',
  },
}));

const StyledCardMedia = styled(CardMedia)({
  height: 300, // Increased height for more impact
  width: '100%',
  objectFit: 'cover',
  transition: 'transform 0.4s ease, filter 0.4s ease',
  '&:hover': {
    transform: 'scale(1.1)', // Zoom effect on hover
    filter: 'brightness(1.2)', // Brighten image on hover
  },
});

const Gallery = () => {
  const images = [
    { src: 'https://edugate-eg.com/wp-content/uploads/2023/04/Tiatro-058.jpg' },
    { src: 'https://edugate-eg.com/wp-content/uploads/2023/05/6011.jpg' },
    { src: 'https://edugate-eg.com/wp-content/uploads/2023/05/6008.jpg' },
    { src: 'https://edugate-eg.com/wp-content/uploads/2023/06/visionmission-01.webp' },
    { src: 'https://edugate-eg.com/wp-content/uploads/2023/08/8O0A1877_result.webp' },
    { src: 'https://edugate-eg.com/wp-content/uploads/2024/03/8O0A0633-scaled.jpg' },
  ];
  const StyledTitle = styled(Typography)({
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: 'clamp(2rem, 4vw, 2.5rem)', // Responsive font size
    color: 'transparent',
    background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    position: 'relative',
    display: 'inline-block',
    marginBottom: '10px',
    textAlign: 'center',  // Align text to center horizontally
    width: '100%', // Ensure the title takes up the full width of the parent container
  
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '-4px',
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: '#C8102E',
      transform: 'scaleX(0)',
      transformOrigin: 'bottom right',
      transition: 'transform 0.3s ease-out',
    },
  
    '&:hover::after': {
      transform: 'scaleX(1)',
      transformOrigin: 'bottom left',
    },
  });
  
  const GradientLine = styled('div')({
    height: '4px',
    width: '120px',
    background: 'linear-gradient(90deg, #C8102E, #000000)',
    margin: '10px auto 30px',
    borderRadius: '2px',
  });
  const Container = styled('section')({
    backgroundColor: '#ffffff',
    color: '#333',
    // padding: '80px 20px',
    maxWidth: '98vw',
    margin: 'auto',
    textAlign: 'center',
    borderRadius: '20px',
    boxShadow: '0 15px 40px rgba(0, 0, 0, 0.1)',
    marginTop: '50px',
  });
  
  
  
  return (
    <Container>
            <StyledTitle>Moments in Time</StyledTitle>
            <GradientLine />

    <GalleryContainer>
      <Grid container spacing={6}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <StyledCardMedia
                image={image.src}
                title={`Image ${index + 1}`}
              />
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </GalleryContainer>
    </Container>

  );
};

export default Gallery;
