import { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardContent, Typography, Box, useMediaQuery } from "@mui/material";
import MapPinIcon from "@mui/icons-material/Place";
import { styled } from "@mui/system";
import { AddToCalendarButton } from 'add-to-calendar-button-react';

const EventsContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
  gap: "16px",
  padding: "20px",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
    gap: "32px",
    padding: "40px",
  },
}));
const GradientLine = styled('div')({
  height: '4px',
  width: '120px',
  background: 'linear-gradient(90deg, #C8102E, #000000)',
  margin: '10px auto 30px',
  borderRadius: '2px',
});
const StyledTitle = styled(Typography)({
  fontFamily: '"Poppins", sans-serif',
  fontWeight: 600,
  fontSize: 'clamp(2rem, 4vw, 2.5rem)', // Responsive font size
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  marginBottom: '10px',
  textAlign: 'center',  // Align text to center horizontally
  width: '100%', // Ensure the title takes up the full width of the parent container

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-4px',
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#C8102E',
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },

  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
});

const Container = styled('section')({
  backgroundColor: '#ffffff',
  color: '#333',
  padding: '60px 15px',
  maxWidth: '95vw',
  margin: 'auto',
  textAlign: 'center',
  borderRadius: '20px',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
  marginTop: '30px',
});
const EventMapLink = styled("a")({
  color: "#1565c0",
  textDecoration: "none",
  fontWeight: "bold",
  fontSize: "1rem",
  '&:hover': { textDecoration: "underline" },
  display: "inline-block",
  marginBottom: "16px",
});
const EventCard = styled(Card)({
  boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
  borderRadius: "15px",
  textAlign: "center",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  backgroundColor: "#fff",
  '&:hover': {
    transform: "scale(1.03)",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)"
  }
});

const TimerContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  marginTop: "20px",
  background: "#f8f9fa",
  padding: "10px",
  borderRadius: "10px",
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.12)",
});

const TimerBlock = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "#d32f2f",
  color: "#fff",
  padding: "12px",
  borderRadius: "8px",
  minWidth: "60px",
  fontSize: "1.2rem",
  fontWeight: "bold",
  boxShadow: "0 3px 8px rgba(0, 0, 0, 0.15)",
});

const formatTime = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return { days, hours, minutes, secs };
};

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await axios.get("https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent");
        setEvents(data.filter(event => event.deleted === "0" && event.is_school === "0"));
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    fetchEvents();
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
        <StyledTitle>Upcoming Events</StyledTitle>
        <GradientLine />
    <EventsContainer>
      {events.map(({ name, location_address, map, start_date, end_date, start_time, embedded_map }) => {
        const eventStart = new Date(`${start_date}T${start_time}`).getTime();
        const timeRemaining = Math.max(0, (eventStart - currentTime) / 1000);
        const { days, hours, minutes, secs } = formatTime(timeRemaining);
        return (
          <EventCard key={name}>
            <CardContent>
              <Typography variant="h5">{name}</Typography>
              <Typography variant="body2" color="textSecondary">📅 {start_date} → {end_date}</Typography>
              <Typography color="textSecondary">📍 {location_address}</Typography>
              <EventMapLink href={map} target="_blank" rel="noopener noreferrer">
                View Map
              </EventMapLink>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", mt: 2 }}>
              <AddToCalendarButton
                name={"EDUGATE Fair at " + location_address + " on the " + start_date + " and until the " + end_date}
                startDate={start_date} 
                options={['Apple', 'Google', 'Yahoo', 'iCal']}
              />
            </Box>
              <TimerContainer>
                <TimerBlock>{days}<span style={{ fontSize: "0.8rem" }}>Days</span></TimerBlock>
                <TimerBlock>{hours}<span style={{ fontSize: "0.8rem" }}>Hrs</span></TimerBlock>
                <TimerBlock>{minutes}<span style={{ fontSize: "0.8rem" }}>Mins</span></TimerBlock>
                <TimerBlock>{secs}<span style={{ fontSize: "0.8rem" }}>Secs</span></TimerBlock>
              </TimerContainer>
              <iframe
                src={embedded_map}
                width="100%"
                height="200"
                style={{ borderRadius: "10px", border: "1px solid #ccc", marginTop: "10px" }}
                allowFullScreen
                loading="lazy"
              />
            </CardContent>
          </EventCard>
        );
      })}
    </EventsContainer>
    </Container>
  );
};

export default UpcomingEvents;
