import React, { useState,useEffect } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Rating,IconButton, Tooltip,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import StarIcon from "@mui/icons-material/Star";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import ReportProblem from '../ReportProblem';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import HelpButton from '../HelpButton';

const SurveyForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState("");
  const [grade, setGrade] = useState("");
  const [school, setSchool] = useState("");
  const [rating, setRating] = useState(0);
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [showFeedback, setShowFeedback] = useState(false); // Feedback message state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setprogress] = useState(false);
  const [whichEvent, setwhichEvent] = useState("");

  
  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000",
      },
      secondary: {
        main: "#000000",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });
  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));
  
  const customIcons = {
    0: { label: '' },
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" style={{ width: '40px', height: '40px' }} />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" style={{ width: '40px', height: '40px' }} />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" style={{ width: '40px', height: '40px' }} />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" style={{ width: '40px', height: '40px' }} />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" style={{ width: '40px', height: '40px' }} />,
      label: 'Very Satisfied',
    },
  };
  
  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value]?.icon || <StarIcon />}</span>;
  }
  
  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const previousStep = () => setCurrentStep((prev) => prev - 1);
  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const [formStates, setFormStates] = useState(null);
  useEffect(() => {
    fetchFormStates();
  }, []);
  
  
  const fetchFormStates = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/formstates');
      setFormStates(response.data.Edugate.student_Feedback);
      setwhichEvent(response.data.survey) // Set whichEvent directly

    } catch (error) {
      console.error('Error fetching form states', error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Extract studentId from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const studentId = urlParams.get('id'); // Retrieve 'id' from query params

    const data = {
        name,
        grade,
        school,
        satisfaction: rating,
        beneficial: answer1,
        enjoyed: answer2,
        suggestions: answer3,
        whichEvent: whichEvent
    };

    setprogress(true);

    try {
        setIsSubmitting(true);

        // Construct the API URL dynamically
        const apiUrl = `https://edutech-backend-471f7cb69116.herokuapp.com/api/student-feedback${studentId ? `/${studentId}` : ''}`;

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.status === 201) {
            setFeedback({
                message: 'Your Response was submitted Successfully. Thank you for your time.',
                type: 'success',
            });
        } else {
            setFeedback({
                message: 'Error submitting the form',
                type: 'error',
            });
        }
    } catch (error) {
        setFeedback({
            message: error.message || 'An error occurred',
            type: 'error',
        });
    }

    setprogress(false);
};


  return (
    <ThemeProvider theme={theme}>
        {formStates && (
            <div style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
              width: '400px'
            }}>
              <Alert 
                icon={<CheckIcon fontSize="inherit" />} 
                severity="error"
                style={{ display: 'flex', alignItems: 'center' }}
              >
    Feedback Form is open only during the Event.          
    </Alert>
            </div>
          )}
              <div style={formStates ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>


    <Box
      sx={{
        background: "linear-gradient(135deg, #8B0000, black)",
        minHeight: "100vh",
        py: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <HelpButton/>
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
          p: 5,
        }}
      >
               <Box sx={{ position: 'relative', right: 16, top: 16 }}> {/* Positioning the ReportProblem component */}
    <Tooltip title="Report a Problem">
    <IconButton
  color="inherit"
  aria-label="notifications"
  sx={{
    mr: 2,
    zIndex: 1200, // Ensures the menu appears on top of other elements
  }}
>
<ReportProblem dynamicNumber="Feeback students Edugate" />
          </IconButton>
        </Tooltip>
        </Box>
        

        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: "darkred", fontWeight: "bold", mb: 3 }}
        >
          Student Feedback
        </Typography>
        {feedback.message=="" ? (

        <Box component="form" noValidate onSubmit={handleSubmit}>
      
          {currentStep === 1 && (
            <>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                margin="normal"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <FormControl required fullWidth margin="normal">
                <InputLabel id="grade-label">Grade</InputLabel>
                <Select
                  labelId="grade-label"
                  label="Grade"
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                  <MenuItem value={12}>12</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="School"
                variant="outlined"
                margin="normal"
                required
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              />
                 <Box
  sx={{
    
    mt: 3, // Add top margin for spacing
  }}
>
              <Button
                variant="contained"
                fullWidth
                onClick={nextStep}
                sx={{
                  mt: 3,
                  backgroundColor: "darkred",
                  color: "white",
                  "&:hover": { backgroundColor: "#8B0000" },
                }}
                disabled={!(name && grade && school)}
              >
                Next
              </Button>
              </Box>
            </>
          )}

          {currentStep === 2 && (
            <>
              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                1) What is your level of satisfaction attending the event?
              </Typography>
              <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              fontSize: '6rem',
            }}
          >
            <StyledRating
              size="large"
              name="satisfaction"
              value={rating}
              IconContainerComponent={IconContainer}
              getLabelText={(value) => customIcons[value]?.label || `${value} Star`}
              highlightSelectedOnly
              onChange={(event, newValue) => setRating(newValue)}
              emptyIcon={<StarIcon style={{ opacity: 0.55, width: '32px', height: '32px' }} />}
              sx={{ fontSize: '6rem' }}
            />
            <Typography ml={2}>
              {rating > 0 ? customIcons[rating]?.label : ''}
            </Typography>
          </Box>
              <Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    mt: 3, // Add top margin for spacing
  }}
>
              <Button  sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": { backgroundColor: "#000000" },
                  mr: 2 

                }} onClick={previousStep}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={nextStep}
                disabled={!rating}
                sx={{
                  backgroundColor: "darkred",
                  color: "white",
                  "&:hover": { backgroundColor: "#8B0000" },
                }}
              >
                Next
              </Button>
              </Box>

            </>
          )}

          {currentStep === 3 && (
            <>
              <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                2) Was it beneficial and met your needs?
              </Typography>
              <TextField
                fullWidth
                required
                label="Your Answer"
                multiline
                rows={2}
                variant="outlined"
                value={answer1}
                onChange={(e) => setAnswer1(e.target.value)}
              />
                <Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    mt: 3, // Add top margin for spacing
  }}
>
              <Button  onClick={previousStep} sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": { backgroundColor: "#000000" },
                  mr: 2 

                }}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={nextStep}
                disabled={!answer1}
                sx={{
                  backgroundColor: "darkred",
                  color: "white",
                  "&:hover": { backgroundColor: "#8B0000" },
                }}
              >
                Next
              </Button>
              </Box>
            </>
          )}

          {currentStep === 4 && (
            <>
              <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                3) What did you enjoy the most about the event?
              </Typography>
              <TextField
                fullWidth
                label="Your Answer"
                multiline
                rows={2}
                required
                variant="outlined"
                value={answer2}
                onChange={(e) => setAnswer2(e.target.value)}
              />
              <Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    mt: 3, // Add top margin for spacing
  }}
>
              <Button  onClick={previousStep}  sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": { backgroundColor: "#000000" },
                  mr: 2 

                }}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={nextStep}
                disabled={!answer2}
                sx={{
                  backgroundColor: "darkred",
                  color: "white",
                  "&:hover": { backgroundColor: "#8B0000" },
                }}
              >
                Next
              </Button>
</Box>
            </>

          )}

          {currentStep === 5 && (
            <>
            
              <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                4) What would you like to see more at EduGate's next event?
              </Typography>
              <TextField
                fullWidth
                label="Your Suggestions"
                multiline
                rows={2}
                required
                variant="outlined"
                value={answer3}
                onChange={(e) => setAnswer3(e.target.value)}
              />
               <Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    mt: 3, // Add top margin for spacing
  }}
>
              <Button  onClick={previousStep}  sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": { backgroundColor: "#000000" },
                  mr: 2 

                }}>
                Back
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!answer3 || isSubmitting || formStates}
                sx={{
                  backgroundColor: "darkred",
                  color: "white",
                  "&:hover": { backgroundColor: "#8B0000" },
                }}
              >
                Submit
              </Button>
              </Box>
              
              {progress && (
    <Box sx={{ width: '100%', marginTop:"10px" }}>
    <LinearProgress />
                    </Box>
                )}
            </>
          )}

        </Box>
      
    ) : (
        <Typography
              variant="h5"
              align="center"
              sx={{ mt: 3, fontWeight: "bold",color: feedback.type === 'error' ? 'red' : 'green' }}
            >

                    {feedback.message}
                  </Typography>
                )}
         
      </Container>
    </Box>
    </div>

    </ThemeProvider>
  );
};

export default SurveyForm;
