import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Box,IconButton,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0033A1"
    },
    secondary: {
      main: "#0033A1"
    }
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const GlobalSearch = () => {
  const username = sessionStorage.getItem('username'); // Get the username from session storage
  const token = localStorage.getItem('token');  // Retrieve the token from localStorage

  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    if (!searchTerm.trim()) return;
    
    setLoading(true);
    setError(null);
    setUser(null);

    try {
      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      const { data } = await axios.get(
        `https://edutech-backend-471f7cb69116.herokuapp.com/api/search-user?query=${searchTerm}`
      ,{
        headers: { 
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
      setUser(data);
    } catch {
      setError("User not found or an error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleAssign = async () => {
    if (!user) return;

    if (!token) {
      // Handle missing token (e.g., redirect to login page)
      console.error('No token found');
      return;
    }

    try {
      await axios.post("https://edutech-backend-471f7cb69116.herokuapp.com/api/assign-user", {
        email: user.email,
        action: "0",
        archive: "0",
        username: username,
      },{
        headers: { 
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
        }
      });
      setUser({ ...user, username });
    } catch (error) {
      console.error("Error assigning user", error);
    }
  };


  return (
    <ThemeProvider theme={theme}>

    <Container maxWidth="sm" sx={{ mt: 5, }}>
      <Paper elevation={6} sx={{ p: 4, borderRadius: 3, bgcolor: "#ffffff" }}>
        <Typography variant="h4" fontWeight="bold" sx={{textAlign: "center" }} color="primary" gutterBottom>
          Global Search
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            fullWidth
            label="Search by Email or Phone"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ bgcolor: "#f9f9f9", borderRadius: 1 }}
          />
         <Button
  variant="contained"
  color="primary"
  onClick={handleSearch}
  disabled={loading}
  startIcon={<SearchIcon />}
  sx={{
    height: 56,
    fontWeight: "bold",
    borderRadius: 30,  // Rounded corners
    background: 'linear-gradient(45deg, #FF8E53, #FF6D00)',  // Gradient color
    '&:hover': {
      background: 'linear-gradient(45deg, #FF6D00, #FF8E53)',  // Hover effect
    },
    paddingX: 3,  // Adjust padding for better text/icon alignment
    fontSize: '1rem',  // Adjust font size for better legibility
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',  // Add some subtle shadow for depth
  }}
>
  Search
</Button>

        </Box>

        {loading && <CircularProgress sx={{ mt: 2 }} />}
        {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}

        {user && (
          <Card sx={{ mt: 3, bgcolor: "#e3f2fd", borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
                User Details
              </Typography>
              {user.f_name && <Typography><strong>First Name:</strong> {user.f_name}</Typography>}
              {user.l_name && <Typography><strong>Last Name:</strong> {user.l_name}</Typography>}
              {user.email && <Typography><strong>Email:</strong> {user.email}</Typography>}
              {user.mobile && <Typography><strong>Phone:</strong> {user.mobile}</Typography>}
              {user.username && <Typography><strong>Assigned Employee:</strong> {user.username}</Typography>}
        {user.archived === "1" && (
          <Typography>
            <strong>Status:</strong> Archived
          </Typography>
        )}
{user.archived === "0" && (
    <>
        {user.contact === "1" && (
          <Typography>
            <strong>Status:</strong> Contact
          </Typography>
        )}

        {user.action === "0" && (
          <Typography>
            <strong>Status:</strong> Transferred Participants
          </Typography>
        )}

        {user.action === "1" && (
          <Typography >
            <strong>Status:</strong> Callback
          </Typography>
        )}

        {user.action === "2" && (
          <Typography >
            <strong>Status:</strong> Counseling
          </Typography>
        )}

        {user.action === "3" && (
          <Typography >
            <strong>Status:</strong> Follow-up
          </Typography>
        )}

        {user.action === "4" && (
          <Typography>
            <strong>Status:</strong> Option Sharing
          </Typography>
           )} 
           </>
        )}
         <Box display="flex" justifyContent="end" mt={2}>
                  <IconButton
                    color="primary"
                    onClick={handleAssign}
                    disabled={user.username === username}
                  >
                    <AssignmentIndIcon />
                  </IconButton>
                </Box>
            </CardContent>
          </Card>
        )}

      </Paper>
    </Container>
    </ThemeProvider>
  );
};

export default GlobalSearch;