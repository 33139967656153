import React, { useEffect, useRef, useState } from "react";
import { Email, Phone, LocationOn, YouTube, Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import { IconButton, Box, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";

const zoomIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Container = styled("section")(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: "#333",
  padding: "80px 20px",
  maxWidth: "1200px",
  margin: "50px auto 0",
  textAlign: "center",
  borderRadius: "20px",
  boxShadow: "0 15px 40px rgba(0, 0, 0, 0.1)",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins, sans-serif",
  fontWeight: 600,
  fontSize: "2.5rem",
  background: "linear-gradient(135deg, #C8102E 0%, #000000 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
  position: "relative",
  display: "inline-block",
  marginBottom: "10px",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-4px",
    left: 0,
    width: "100%",
    height: "2px",
    backgroundColor: "#C8102E",
    transform: "scaleX(0)",
    transformOrigin: "bottom right",
    transition: "transform 0.3s ease-out",
  },
  "&:hover::after": {
    transform: "scaleX(1)",
    transformOrigin: "bottom left",
  },
}));

const GradientLine = styled("div")({
  height: "4px",
  width: "120px",
  background: "linear-gradient(90deg,#C8102E, #000000)",
  margin: "10px auto 30px",
  borderRadius: "2px",
});

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.3rem",
  color: "#666",
  marginBottom: "50px",
  maxWidth: "700px",
  margin: "0 auto",
  textAlign: "center",
}));

const ContactCard = styled(Box)(({ theme, isVisible }) => ({
  background: "linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(245, 245, 245, 0.9))",
  borderRadius: "15px",
  padding: "25px 20px",
  width: "260px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
  animation: isVisible ? `${zoomIn} 1s ease forwards` : "none",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0px 15px 25px rgba(139, 0, 0, 0.5)",
  },
  svg: {
    fontSize: "3rem",
    marginBottom: "10px",
  },
  ".email-icon": {
    color: "#0072c6",
  },
  ".phone-icon": {
    color: "#34a853",
  },
  "p, a": {
    fontSize: "1.1rem",
    color: "#333",
    margin: "4px 0",
    textAlign: "center",
    textDecoration: "none",
    "&:hover": {
      color: "#8b0000",
      textDecoration: "underline",
    },
  },
  a: {
    fontWeight: "bold",
  },
  "@media (max-width: 600px)": {
    width: "90%",
    padding: "20px 15px",
    fontSize: "1rem",
  },
}));

const ContactCard2 = styled(ContactCard)({
  height: "200px",
  justifyContent: "center",
});

const ContactSection = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "20px",
  marginTop: "40px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "center",
  },
});

const SocialIcons = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  a: {
    fontSize: "3rem",
    transition: "color 0.3s",
    "&.instagram": {
      color: "#e4405f",
      "&:hover": { color: "#f77737" },
    },
    "&.facebook": {
      color: "#3b5998",
      "&:hover": { color: "#4267B2" },
    },
    "&.linkedin": {
      color: "#0077b5",
      "&:hover": { color: "#0a66c2" },
    },
  },
});

const MapContainer = styled(Box)({
  width: "100%",
  height: "400px",
  marginTop: "40px",
  borderRadius: "15px",
  overflow: "hidden",
  boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
  iframe: {
    width: "100%",
    height: "100%",
    border: "0",
  },
});


const ReadyToJoinUs = () => {
  const [visibleCards, setVisibleCards] = useState([false, false, false]);
  const cardRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          setVisibleCards((prev) => {
            const newVisibleCards = [...prev];
            newVisibleCards[index] = true; // Set the corresponding card to visible
            return newVisibleCards;
          });
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    }, options);

    cardRefs.current.forEach(card => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      observer.disconnect(); // Clean up the observer on unmount
    };
  }, []);

  return (
    <Container id='contact'>
      <Title>Ready to Join Us?</Title>
      <GradientLine />
      <Subtitle>Connect with us through your favorite platform, or visit us directly!</Subtitle>
      
      <ContactSection>
        <ContactCard ref={el => cardRefs.current[0] = el} isVisible={visibleCards[0]}>
          <IconButton className="email-icon">
            <Email />
          </IconButton>
          <a href="mailto:info@edugate-eg.com">info@edugate-eg.com</a>
        </ContactCard>
        
        <ContactCard ref={el => cardRefs.current[1] = el} isVisible={visibleCards[1]}>
          <IconButton className="phone-icon">
            <Phone />
          </IconButton>
          <a href={`tel:+201000429759`}>+20 1000 42 97 59</a>
        </ContactCard>
        
        <ContactCard2 ref={el => cardRefs.current[2] = el} isVisible={visibleCards[2]}>
          <SocialIcons>
            <a href="https://www.instagram.com/edugate.eg/" target="_blank" rel="noopener noreferrer" className="instagram">
              <IconButton>
                <Instagram  sx={{color: "#e4405f"}} />
              </IconButton>
            </a>
            <a href="https://www.facebook.com/edugate.eg" target="_blank" rel="noopener noreferrer" className="facebook">
              <IconButton>
                <Facebook sx={{color: "#3b5998"}} />
              </IconButton>
            </a>
            <a href="https://www.linkedin.com/company/edugate-eg/" target="_blank" rel="noopener noreferrer" className="linkedin">
              <IconButton>
                <LinkedIn sx={{color: "#0077b5"}} />
              </IconButton>
            </a>
          </SocialIcons>
        </ContactCard2>
      </ContactSection>

      <MapContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3453.9047066681255!2d31.230556075653567!3d30.03959161880045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAyJzIyLjUiTiAzMcKwMTMnNTkuMyJF!5e0!3m2!1sen!2seg!4v1710014290514!5m2!1sen!2seg"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </MapContainer>
    </Container>
  );
};

export default ReadyToJoinUs;
